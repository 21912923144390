import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  loadingContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0', // Optional: change background color for better visibility
  },
  loadingText: {
    marginTop: 16,
  },
  dots: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& > div': {
      width: 10,
      height: 10,
      margin: 2,
      backgroundColor: 'currentColor',
      borderRadius: '50%',
      animation: '$dots 1.2s infinite ease-in-out both',
    },
    '& > div:nth-child(1)': {
      animationDelay: '0s',
    },
    '& > div:nth-child(2)': {
      animationDelay: '0.2s',
    },
    '& > div:nth-child(3)': {
      animationDelay: '0.4s',
    },
  },
  '@keyframes dots': {
    '0%, 80%, 100%': {
      transform: 'scale(0.6)',
    },
    '40%': {
      transform: 'scale(1)',
    },
  },
});

const Loading = () => {
  const classes = useStyles();

  return (
    <Box className={classes.loadingContainer}>
      <Box className={classes.dots}>
        <div></div>
        <div></div>
        <div></div>
      </Box>
      <Typography variant="h6" className={classes.loadingText}>
        Loading...
      </Typography>
    </Box>
  );
};

export default Loading;
