import React, { useState } from 'react';
import { Typography, Paper, TextField, Box, Button, Container, Link } from '@mui/material';
import axios from 'axios';

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error message for the current field
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.fullName = formData.fullName ? '' : 'Name is required';
    tempErrors.email = formData.email ? '' : 'Email is required';
    tempErrors.phone = formData.phone ? '' : 'Phone number is required';
    tempErrors.message = formData.message ? '' : 'Message is required';

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const response = await axios.post('https://lgn.licensedtaxi.co.uk/contactus/form/submit', {
          zone_id: 9,
          full_name: formData.fullName,
          email_address: formData.email,
          mobile: formData.phone,
          message: formData.message,
          status: 'pending',
        });

        if (response.data.status === 'success') {
          setConfirmationMessage('Your message has been sent successfully!');
        } else {
          setConfirmationMessage('Your message has not been sent successfully! Please try again.');
        }
        setFormData({
          fullName: '',
          email: '',
          phone: '',
          message: '',
        });
      } catch (error) {
        console.error('Error submitting form', error);
        setConfirmationMessage('There was an error submitting the form. Please try again later.');
      }
    }
  };

  return (
    <Container maxWidth="sm" style={{ padding: '20px' }}>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px', maxWidth: '600px', margin: 'auto' }}>
        <Typography
          variant="h4"
          gutterBottom
          style={{
            color: '#b02519',
            textAlign: 'center',
            marginBottom: '20px',
            fontSize: '2.125rem',
            fontWeight: 400,
            lineHeight: 1.235,
            letterSpacing: '0.00735em',
          }}
        >
          Business Form
        </Typography>
        <form onSubmit={handleSubmit} noValidate>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Name"
              variant="outlined"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              error={!!errors.fullName}
              helperText={errors.fullName && <span style={{ color: 'red' }}>{errors.fullName}</span>}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Phone"
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
            />
          </Box>
          <Box mb={2}>
            <TextField
              fullWidth
              label="Your message"
              variant="outlined"
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
              error={!!errors.message}
              helperText={errors.message && <span style={{ color: 'red' }}>{errors.message}</span>}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            type="submit"
          >
            SEND
          </Button>
        </form>
        {confirmationMessage && (
          <Typography
            variant="body1"
            style={{
              marginTop: '20px',
              color: 'green',
              textAlign: 'center',
            }}
          >
            {confirmationMessage}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{
            marginTop: '20px',
            textAlign: 'center',
          }}
        >
          Are you interested in filling out all the details? <Link href="/business-form">Click here</Link>
        </Typography>
      </Paper>
    </Container>
  );
};

export default ContactUsForm;
