import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Loading from './customComponents/Loading';
import React, { lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BasicBusiness from './pages/BasicBusniess';


const Home = lazy(() => import('../components/Layout/Home'));
const AboutUs = lazy(() => import('./pages/AboutUs'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const Driver = lazy(() => import('./pages/Driver'));
const Rider = lazy(() => import('./pages/Rider'));
const Business = lazy(() => import('./pages/Business'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const BlogDetails = lazy(() => import('./customComponents/BlogDetails'));
const Blog = lazy(() => import('./customComponents/Blog'));
const AirPortTransDetails = lazy(() => import('./pages/AirPortTransDetails'));
const ScheduleRideDetails = lazy(() => import('./pages/ScheduleRideDetails'))
const FAQs = lazy(() => import('./pages/FAQs'))
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'))
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))

function AppNavigator() {

  useEffect(() => {
    const loadData = async () => {
      // Simulate data loading or any other side effects if needed
    };

    const timeoutId = setTimeout(loadData, 2000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <BrowserRouter>
      <Header />
        <React.Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/service" element={<ContactUs />} />
            <Route path="/drivers" element={<Driver />} />
            <Route path="/riders" element={<Rider />} />
            <Route path="/business" element={<BasicBusiness />} />
            <Route path="/business-form" element={<Business />} />
            <Route path="/blogs/:alias_name" element={<BlogDetails />} />
            <Route path="/blogs" element={<Blog />} />
            <Route path="/airportTrasport-info" element={<AirPortTransDetails />} />
            <Route path="/scheduled-ride-info" element={<ScheduleRideDetails />} />
            <Route path="/faqs" element={<FAQs />} />
            <Route path="/termsConditions" element={<TermsAndConditions />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </React.Suspense>
      <Footer />
    </BrowserRouter>
  );
}

export default AppNavigator;
